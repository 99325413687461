var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    { staticClass: "text-center", attrs: { "fill-height": "" } },
    [
      _c(
        "v-row",
        { attrs: { align: "center" } },
        [
          _c(
            "v-col",
            [
              _c("h1", { staticClass: "text-h1 primary--text" }, [
                _vm._v("Ops, 404")
              ]),
              _c("p", { staticClass: "text-h5 mt-4" }, [
                _vm._v("A página não foi encontrada!")
              ]),
              _c(
                "v-btn",
                { attrs: { to: "/", color: "primary", outlined: "" } },
                [_vm._v(" Voltar para o início! ")]
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }